import { useState } from 'react'

import { defaultCallback, useLead } from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import EditForm from './components/EditForm'
import './hero-refin.scss'

const RefinCondition = (props) => {
  const { currentProposal } = props
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const { offers } = useLead()
  const proposal = offers?.hero ?? currentProposal

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    heroOfferHighlights,
    buttonTrackingName
  } = proposal

  return (
    <div>
      <div className="hero-refin">
        <div className="hero-offer__header">
          <p className="hero-offer__title">{title}</p>
        </div>

        <div className="hero-offer__content">
          <div className="hero-offer__value-container">
            <EditForm
              proposal={currentProposal}
              setCardButtonDisabled={setIsButtonDisabled}
            />
          </div>

          <AdditionalData list={heroOfferHighlights} />
          <div className="hero-offer__buttons-wrapper">
            <ButtonContinue
              {...props}
              buttonTracking={buttonTrackingName}
              buttonText={buttonText}
              callback={defaultCallback}
              disabled={isButtonDisabled}
              buttonAriaLabel={buttonAriaLabel}
              dataTestid={`hero-offer-refin-btn`}
              id={`hero-offer-${productCode}-btn`}
            />
          </div>

          <Disclaimer />
        </div>
      </div>
    </div>
  )
}

export default RefinCondition
