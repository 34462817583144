import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks/useOnContinue'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './hero-itau-account.scss'

const ItauAccount = (props) => {
  const { currentProposal } = props

  const {
    title,
    highlights,
    productCode,
    subtitleBig,
    buttonText,
    buttonTrackingName
  } = currentProposal

  return (
    <div>
      <div className="hero-offer__header">
        <p className="hero-offer__title">{title}</p>
      </div>

      <div className="itau-account">
        <div className="hero-offer__value-block">
          <p className="itau-account__title">{subtitleBig}</p>
        </div>

        <AdditionalData list={highlights} />

        <ButtonContinue
          {...props}
          buttonTracking={buttonTrackingName}
          buttonText={buttonText}
          callback={finishProposalCallback}
          id={`hero-offer-${productCode}-btn`}
          dataTestid={`hero-offer-itau_account-btn`}
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default ItauAccount
