import { useState } from 'react'

import { defaultCallback } from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import EditForm from './components/EditForm'
import './second-consig.scss'

const ConsigNcor = (props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const { currentProposal } = props

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    buttonTrackingName,
    secondOfferHighlights
  } = currentProposal

  return (
    <div className="second-offer-card__wrapper second-offer-card__consig">
      <div>
        <div className="second-offer-card__header">
          <p className="second-offer-card__title --fixed-height">{title}</p>
        </div>

        <EditForm
          setCardButtonDisabled={setIsButtonDisabled}
          currentProposal={props.currentProposal}
        />
      </div>

      <div className="second-offer-card__final-content">
        <AdditionalData list={secondOfferHighlights} />

        <ButtonContinue
          {...props}
          disabled={isButtonDisabled}
          buttonText={buttonText}
          callback={defaultCallback}
          ariaLabel={buttonAriaLabel}
          id={`second-offer-${productCode}-btn`}
          dataTestid={'secondary-offer-consig-btn'}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
        />
        <Disclaimer />
      </div>
    </div>
  )
}

export default ConsigNcor
