import { If, ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { defaultCallback } from '@itau-loans-www/shopping/src/hooks/useOnContinue'
import { formatters } from 'utils'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'

const DefaultCondition = (props) => {
  const { currentProposal } = props

  const {
    maxLoanValue,
    installmentsValue,
    maxInstallmentsNumber,
    requestedLoanValue,
    installmentsNumber,
    interestRateMonthly,
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    heroOfferHighlights
  } = currentProposal

  const isMaxLoanValueAfterEdit = maxLoanValue === requestedLoanValue
  const installments =
    installmentsNumber > 0 ? installmentsNumber : maxInstallmentsNumber

  return (
    <div>
      <div className="hero-offer__header">
        <p className="hero-offer__title">{title}</p>
      </div>

      <div className="hero-offer__content">
        <div className="hero-offer__value-container">
          <div className="hero-offer__value-block">
            <p className="hero-offer__subtitle">valor do empréstimo</p>
            <p className="hero-offer__title">
              <span className="hero-offer__subtitle --bold">R$</span>{' '}
              {formatters.number.toFloat(requestedLoanValue)}
            </p>
            <p className="hero-offer__disclaimer-info">
              <If
                condition={!isMaxLoanValueAfterEdit}
                renderIf={
                  <>máximo de {formatters.number.toCurrency(maxLoanValue)}</>
                }
              />
            </p>
          </div>
          <div className="hero-offer__value-block">
            <p className="hero-offer__subtitle">condições de pagamento</p>
            <p className="hero-offer__title">
              {installments}x{' '}
              <span className="hero-offer__subtitle --bold">de R$</span>{' '}
              {formatters.number.toFloat(installmentsValue)}
            </p>
            <p className="hero-offer__disclaimer-info">
              taxas de {formatters.number.toPercent(interestRateMonthly, 2)}{' '}
              a.m.
            </p>
          </div>
        </div>

        <AdditionalData list={heroOfferHighlights} />

        <ButtonContinue
          {...props}
          buttonText={buttonText}
          callback={defaultCallback}
          buttonAriaLabel={buttonAriaLabel}
          id={`hero-offer-${productCode}-btn`}
          dataTestid="default"
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default DefaultCondition
