import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useMapper, useLead } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'

export const useEditForm = ({
  setCardButtonDisabled,
  currentProposalUpdated,
  installments,
  paymentOptions,
  offerIdx
}) => {
  const { requestedLoanValue, offerUuid } = currentProposalUpdated
  const { changeOffers, offers } = useLead()

  const [valueStatus, setValueStatus] = useState('saved')
  const [installmentStatus, setInstallmentStatus] = useState('saved')
  const [isFormSending, setIsFormSending] = useState(false)
  const [isToRerender, setIsToRerender] = useState(false)

  const [requestedDefaultValue, setRequestedDefaultValue] =
    useState(requestedLoanValue)

  const { mapSimulationsWhenEdit } = useMapper()

  const getDefaultPaymentOption = (
    paymentOptionsValues,
    installmentsValues
  ) => {
    const index =
      installmentsValues?.findIndex(({ selected }) => selected === true) ?? 0

    return {
      paymentOption: paymentOptionsValues[index]?.label,
      installmentsNumber: installmentsValues[index]?.installments_number,
      installmentsValue: installmentsValues[index]?.installments_value
    }
  }

  const {
    control,
    formState: { errors },
    ...formProps
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: requestedLoanValue,
      paymentOptions: '',
      installmentsNumber: '',
      installmentsValue: ''
    }
  })

  useEffect(() => {
    const newInstallmentsValues = getDefaultPaymentOption(
      paymentOptions,
      installments
    )

    formProps.setValue(
      'installmentsNumber',
      newInstallmentsValues?.installmentsNumber
    )
    formProps.setValue(
      'installmentsValue',
      newInstallmentsValues?.installmentsValue
    )
    formProps.setValue('paymentOptions', newInstallmentsValues?.paymentOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getValues, setValue } = formProps

  const onEditClick = (setIsEditing) => {
    setIsEditing('editing')
    setCardButtonDisabled(true)
  }

  // Por limitações do Currency Input do DS
  // para alterar o valor fora do input
  // é necessário renderizar ele novamente
  const updateInstallmentValue = (installmentsValue, value) =>
    new Promise((resolve) => {
      setValue('installmentsValue', installmentsValue)
      setRequestedDefaultValue(value)
      setIsToRerender(true)
      resolve()
    })

  const onSubmit = async (setIsEditing) => {
    try {
      setIsFormSending(true)
      setCardButtonDisabled(true)
      setIsEditing('sending')

      const newData = await http.proposal.editProposalData(
        offerUuid,
        getValues()
      )
      setIsFormSending(false)

      await updateInstallmentValue(
        newData?.installments_value,
        newData?.requested_loan_value
      )

      setIsToRerender(false)

      const formattedData = mapSimulationsWhenEdit(newData)

      const updatedOffer = offers?.others.map((item, idx) =>
        offerIdx === idx ? formattedData : item
      )

      changeOffers({
        others: updatedOffer
      })

      const newInstallmentsValues = getDefaultPaymentOption(
        formattedData?.additionalData?.paymentOptions,
        formattedData?.additionalData?.installments
      )

      formProps.setValue(
        'installmentsNumber',
        newInstallmentsValues?.installmentsNumber
      )
      formProps.setValue(
        'installmentsValue',
        newInstallmentsValues?.installmentsValue
      )
      formProps.setValue('paymentOptions', newInstallmentsValues?.paymentOption)

      setIsEditing('saved')
      setCardButtonDisabled(false)
    } catch {
      setIsEditing('saved')
      setIsFormSending(false)
      setCardButtonDisabled(false)
    }
  }

  return {
    onSubmit,
    isFormSending,
    valueStatus,
    setValueStatus,
    installmentStatus,
    setInstallmentStatus,
    requestedDefaultValue,
    onEditClick,
    control,
    errors,
    isToRerender,
    formProps
  }
}
