import { If, ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { defaultCallback } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'

const DefaultCondition = (props) => {
  const { number } = formatters

  const { currentProposal } = props

  const {
    maxLoanValue,
    installmentsValue,
    requestedLoanValue,
    installmentsNumber,
    maxInstallmentsNumber,
    interestRateMonthly,
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    buttonTrackingName,
    secondOfferHighlights
  } = currentProposal

  const isMaxLoanValueAfterEdit = maxLoanValue === requestedLoanValue
  const installments =
    installmentsNumber > 0 ? installmentsNumber : maxInstallmentsNumber

  return (
    <div className="second-offer-card__wrapper">
      <div className="second-offer-card__header">
        <p className="second-offer-card__title --fixed-height">{title}</p>
      </div>

      <div className="second-offer-card__main-content">
        <div className="second-offer-card__info">
          <p className="second-offer-card__info-title">Valor do empréstimo</p>
          <p className="second-offer-card__info-text">
            <span className="second-offer-card__info-title">R$</span>{' '}
            {number.toFloat(requestedLoanValue)}
          </p>
          <p className="second-offer-card__disclaimer-info">
            <If
              condition={!isMaxLoanValueAfterEdit}
              renderIf={<>Máximo de {number.toCurrency(maxLoanValue)}</>}
            />
          </p>
        </div>

        <div className="second-offer-card__info">
          <p className="second-offer-card__info-title">
            Condições de pagamento
          </p>
          <p className="second-offer-card__info-text">
            {installments}x{' '}
            <span className="second-offer-card__info-title">de R$</span>{' '}
            {number.toFloat(installmentsValue)}
          </p>
          <p className="second-offer-card__disclaimer-info">
            Taxa de {number.toPercent(interestRateMonthly, 2)} a.m.
          </p>
        </div>

        <AdditionalData list={secondOfferHighlights} />
      </div>

      <div className="second-offer-card__final-content">
        <ButtonContinue
          {...props}
          buttonText={buttonText}
          callback={defaultCallback}
          ariaLabel={buttonAriaLabel}
          id={`second-offer-${productCode}-btn`}
          dataTestid={'secondary-offer-default-btn'}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default DefaultCondition
