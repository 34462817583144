import { useState, useEffect } from 'react'

import { segment } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import Cgi from './Cgi'
import Consig from './Consig'
import ConsigCold from './ConsigCold'
import ConsigNcor from './ConsigNcor'
import Consorcio from './Consorcio'
import CreditCardOffer from './CreditCardOffer'
import Debts from './Debts'
import DefaultCondition from './Default'
import FGTSCondition from './FGTS'
import FGTSColdCondition from './FGTS/FGTSNoEdit'
import ItauAccount from './ItauAccount'
import Refin from './Refin'

export default ({ proposal, offerIdx }) => {
  const originalProposal = proposal
  const productCode = proposal?.productCode
  const [isButtonLoading, setButtonLoading] = useState(false)
  const [currentProposal, setCurrentProposal] = useState(proposal)
  const { maxLoanValue, requestedLoanValue } = proposal
  const isMaxLoanValue = requestedLoanValue === maxLoanValue
  const FgtsOffer = maxLoanValue ? FGTSCondition : FGTSColdCondition

  const props = {
    isMaxLoanValue,
    isButtonLoading,
    currentProposal,
    originalProposal,
    setButtonLoading,
    setCurrentProposal,
    offerIdx
  }

  const specialConditions = {
    recovery_debts: <Debts {...props} />,
    cgi: <Cgi {...props} />,
    FGTS: <FgtsOffer {...props} />,
    fgts_app: <FgtsOffer {...props} isWebAppFlux />,
    itau_account: <ItauAccount {...props} />,
    azul_infinite_card: <CreditCardOffer {...props} />,
    azul_platinum_card: <CreditCardOffer {...props} />,
    consig_ncor: <ConsigNcor {...props} />,
    'CONSIG CORR REFIN OP': <Refin {...props} />,
    'CONSIG CORR REFIN EP': <Refin {...props} />,
    'CONSIG CORR REFIN OP / Person': <Refin {...props} />,
    'CONSIG CORR REFIN EP / Person': <Refin {...props} />,
    'CONSIG CORR EP': <Consig {...props} />,
    'CONSIG CORR EP / Person': <Consig {...props} />,
    'CONSIG CORR OP': <Consig {...props} />,
    'CONSIG CORR OP / Person': <Consig {...props} />,
    'CONSIG CORR REFIN INSS': <Refin {...props} />,
    'CONSIG CORR REFIN INSS / Person': <Refin {...props} />,
    reneg_debts: <Debts {...props} />,
    consig_refin_inss_ncor: <ConsigNcor {...props} />,
    consig_pa_ep_cold: <ConsigCold {...props} />,
    consig_pcon_op_cold: <ConsigCold {...props} />,
    consig_pcon_inss_cold: <ConsigCold {...props} />,
    consortium: <Consorcio {...props} />,
    blue_infinite_card: <CreditCardOffer {...props} />,
    pda_inter_card: <CreditCardOffer {...props} />,
    uniclass_signature_card: <CreditCardOffer {...props} />,
    personnalite_black_pontos_card: <CreditCardOffer {...props} />,
    latam_pass_platinum: <CreditCardOffer {...props} />,
    latam_pass_black: <CreditCardOffer {...props} />,
    pda_black_card: <CreditCardOffer {...props} />,
    passai_gold_card: <CreditCardOffer {...props} />
  }

  const currentProduct = specialConditions[productCode]

  useEffect(() => {
    segment.pageView({
      data: window,
      payload: {
        productName: productCode,
        type: 'Carrossel',
        order: offerIdx
      }
    })

    if (!currentProduct) {
      DDPageError({
        name: 'SecondOffer',
        payload: {
          productCode,
          errorMessage: 'Product not found'
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return currentProduct ?? <DefaultCondition {...props} />
}
