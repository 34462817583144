import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getNextStepBlocked = async (productCode) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: `step-blocked-offer/?product=${productCode}`,
    method: 'get'
  })

  return data
}

export default { getNextStepBlocked }
