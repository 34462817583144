import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useMapper, useLead } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'

export const useEditForm = ({ setCardButtonDisabled, proposal }) => {
  const { requestedLoanValue, offerUuid, installmentsValue } = proposal
  const { changeOffers } = useLead()

  const [valueStatus, setValueStatus] = useState('saved')
  const [installmentStatus, setInstallmentStatus] = useState('saved')
  const [isFormSending, setIsFormSending] = useState(false)
  const [isToRerender, setIsToRerender] = useState(false)
  const [installmentDefaultValue, setInstallmentDefaultValue] =
    useState(installmentsValue)
  const [requestedDefaultValue, setRequestedDefaultValue] =
    useState(requestedLoanValue)

  const { mapSimulationsWhenEdit } = useMapper()

  const {
    control,
    getValues,
    setValue,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: requestedLoanValue,
      installmentsValue: installmentsValue
    }
  })

  const onEditClick = (setIsEditing) => {
    setIsEditing('editing')
    setCardButtonDisabled(true)
  }

  // Por limitações do Currency Input do DS
  // para alterar o valor fora do input
  // é necessário renderizar ele novamente
  const updateInstallmentValue = (installmentsValue, value) =>
    new Promise((resolve) => {
      setValue('installmentsValue', installmentsValue)
      setValue('value', value)

      setInstallmentDefaultValue(installmentsValue)
      setRequestedDefaultValue(value)
      setIsToRerender(true)
      resolve()
    })

  const onSubmit = async (setIsEditing) => {
    try {
      setIsFormSending(true)
      setCardButtonDisabled(true)
      setIsEditing('sending')

      const newData = await http.proposal.editProposalData(
        offerUuid,
        getValues()
      )
      setIsFormSending(false)

      await updateInstallmentValue(
        newData?.installments_value,
        newData?.requested_loan_value
      )

      setIsToRerender(false)

      const formattedData = mapSimulationsWhenEdit(newData)

      changeOffers({
        hero: formattedData
      })

      setIsEditing('saved')
      setCardButtonDisabled(false)
    } catch {
      setIsEditing('saved')
      setCardButtonDisabled(false)
      setIsFormSending(false)
    }
  }

  return {
    onSubmit,
    isFormSending,
    valueStatus,
    setValueStatus,
    installmentStatus,
    setInstallmentStatus,
    installmentDefaultValue,
    requestedDefaultValue,
    onEditClick,
    control,
    isValid,
    errors,
    isToRerender
  }
}
