const allProducts = [
  {
    title: 'Consórcio',
    text: 'Conquiste seus bens com planejamento, sem juros e sem entrada.',
    icon: 'consorcio_outline',
    link: 'https://consorcio.itau.com.br/?utm_source=cross-crediario&utm_context=vitrine-crediario&utm_camp=marketplace-outras-maneiras&utm_med=cross-sell&utm_sou=shopping',
    textLink: 'Conheça',
    trackName: 'Consorcio'
  },
  {
    title: 'Microcrédito',
    text: 'Crédito para quem possui um pequeno negócio ou para quem trabalha por conta própria. É possível utilizar de diversas formas.',
    icon: 'cifrao',
    link: 'https://www.itau.com.br/emprestimos-financiamentos/microcredito/?vitrine_org&utm_source=cross-crediario&utm_context=vitrine-crediario&utm_camp=vitrine-crediario&utm_med=cross-sell&utm_sou=shopping',
    textLink: 'Conheça',
    trackName: 'Microcredito'
  },
  {
    title: 'Antecipação do 13º salário',
    text: 'Ideal para imprevistos ou emergências no curto prazo.',
    icon: 'inovacao_outline',
    link: 'https://www.itau.com.br/emprestimos-financiamentos/antecipacao-decimo-terceiro-salario/?utm_source=cross-crediario&utm_context=vitrine-crediario&utm_camp=vitrine-crediario&utm_med=cross-sell&utm_sou=shopping',
    textLink: 'Conheça',
    trackName: 'Atencipacao13Salario'
  },
  {
    title: 'Crédito imobiliário',
    text: 'É um financiamento que possibilita a compra de um imóvel com ótimas taxas.',
    icon: 'home_outline',
    link: 'https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/?utm_source=cross-crediario&utm_context=vitrine-crediario&utm_camp=marketplace-outras-maneiras&utm_med=cross-sell&utm_sou=shopping',
    textLink: 'Conheça',
    trackName: 'CreditoImobiliario'
  },
  {
    title: 'Cartão de crédito',
    text: 'Escolha o melhor cartão para você.',
    icon: 'cartao_outline',
    link: 'https://www.itau.com.br/cartoes/escolha/?utm_source=cross-crediario&utm_context=vitrine-crediario&utm_camp=marketplace-outras-maneiras&utm_med=cross-sell&utm_sou=shopping',
    textLink: 'Conheça',
    trackName: 'CartaoDeCredito'
  },
  {
    title: 'Limite da Conta',
    text: 'Ideal para imprevistos ou emergências no curto prazo.',
    icon: 'cheque_outline',
    link: 'https://www.itau.com.br/emprestimos-financiamentos/limite-da-conta/?vitrine_org&utm_source=cross-crediario&utm_context=vitrine-crediario&utm_camp=vitrine-crediario&utm_med=cross-sell&utm_sou=shopping',
    textLink: 'Conheça',
    trackName: 'LimiteDaConta'
  },
  {
    title: 'Itaú Parcela Fácil',
    text: 'Parcele suas compras online diretamente com o Itaú.',
    icon: 'piscada_outline',
    link: 'https://www.itau.com.br/emprestimos-financiamentos/itau-parcela-facil/?utm_source=cross-crediario&utm_context=vitrine-crediario&utm_camp=vitrine-crediario&utm_med=cross-sell&utm_sou=shopping',
    textLink: 'Conheça',
    trackName: 'ParcelaFacil'
  },
  {
    title: 'Seguros',
    text: 'Uma maneira de você poder se proteger de acordo com o seu momento de vida.',
    icon: 'cobertura_de_seguros_outline',
    link: 'https://www.lojadesegurositau.com.br/?utm_source=cross-crediario&utm_context=vitrine-crediario&utm_camp=marketplace-outras-maneiras&utm_med=cross-sell&utm_sou=shopping',
    textLink: 'Conheça',
    trackName: 'Seguros'
  },
  {
    title: 'Conta corrente',
    text: 'Abra sua conta online e confira os benefícios de ser Itaú.',
    icon: 'cobranca',
    link: 'https://www.itau.com.br/abra-sua-conta/tp/home/?utm_source=st&utm_medium=nd&utm_campaign=st-conv-nd-st-nd-all&utm_content=nd-nd-nd-iacnta-nd-pfaqu-MT00001342-rvproduto&utm_term=shopping-x-lp-card&utm_sou=shopping&utm_med=x&utm_camp=lp&utm_cont=card&utm_ter=32',
    textLink: 'Conheça',
    trackName: 'ContaCorrente'
  },
  {
    title: 'Adiantamento a depositante',
    text: 'Serviço de análise emergencial de crédito.',
    icon: 'salario_outline',
    link: 'https://www.itau.com.br/emprestimos-financiamentos/adiantamento-depositante/?vitrine_org&utm_source=cross-crediario&utm_context=vitrine-crediario&utm_camp=vitrine-crediario&utm_med=cross-sell&utm_sou=shopping',
    textLink: 'Conheça',
    trackName: 'AdiantamentoDepositante'
  }
]

export default allProducts
