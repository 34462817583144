import { IdsContextualButton, IdsMainButton } from '@ids/react'
import { If, Input, Select } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import { SkeletonLoaderInput } from '../../../../../../components/SkeletonLoader'
import { useEditForm } from './hooks'
import './second-offer-consigNcor-edit-form.scss'

const EditForm = ({
  setCardButtonDisabled,
  currentProposal,
  offerIdx,
  loanLabel = 'Valor adicional na sua conta'
}) => {
  const { offers } = useLead()

  const currentProposalUpdated =
    offers?.others && offers?.others[offerIdx]
      ? offers?.others[offerIdx]
      : currentProposal

  const { maxLoanValue, minLoanValue, interestRateMonthly, additionalData } =
    currentProposalUpdated

  const { installments, paymentOptions } = additionalData

  const {
    onSubmit,
    isFormSending,
    valueStatus,
    setValueStatus,
    installmentStatus,
    setInstallmentStatus,
    requestedDefaultValue,
    onEditClick,
    control,
    errors,
    isToRerender,
    formProps
  } = useEditForm({
    currentProposalUpdated,
    setCardButtonDisabled,
    offerIdx,
    installments,
    paymentOptions
  })

  return (
    <div className="second-offer-consigNcor-edit-form">
      <div className="second-offer-consigNcor-edit-form__value-wrapper">
        <div className="second-offer-consigNcor-edit-form__field">
          <If
            condition={isFormSending}
            renderIf={<SkeletonLoaderInput />}
            renderElse={
              <>
                <Input.Currency
                  id="value"
                  label={loanLabel}
                  name="value"
                  type="currency"
                  shouldResetOn={isToRerender}
                  disabled={valueStatus === 'saved' || isFormSending}
                  defaultValue={requestedDefaultValue}
                  rules={{
                    min: minLoanValue,
                    max: maxLoanValue
                  }}
                  control={control}
                />

                <p className="second-offer-consigNcor-edit-form__disclaimer">
                  Máximo de {formatters.number.toCurrency(maxLoanValue)}
                </p>
              </>
            }
          />
        </div>

        <If
          condition={['editing', 'sending'].includes(valueStatus)}
          renderIf={
            <IdsMainButton
              isLoading={isFormSending}
              data-testid="ok-edit-value-btn"
              onClick={() => onSubmit(setValueStatus)}
              disabled={
                installmentStatus === 'editing' ||
                isFormSending ||
                !!errors.value
              }
            >
              Ok
            </IdsMainButton>
          }
          renderElse={
            <IdsContextualButton
              data-testid="edit-value-btn"
              onClick={() => onEditClick(setValueStatus)}
              disabled={installmentStatus === 'editing' || isFormSending}
            >
              Editar
            </IdsContextualButton>
          }
        />
      </div>
      <div className="second-offer-consigNcor-edit-form__value-wrapper --select">
        <div className="second-offer-consigNcor-edit-form__field">
          <If
            condition={isFormSending}
            renderIf={<SkeletonLoaderInput />}
            renderElse={
              <>
                <Select
                  label="Condições de pagamento"
                  name="paymentOptions"
                  control={control}
                  options={paymentOptions}
                  formProps={formProps}
                  disabled={installmentStatus === 'saved' || isFormSending}
                  onChange={({ value }) => {
                    formProps.setValue(
                      'installmentsNumber',
                      installments[value]?.installments_number
                    )
                    formProps.setValue(
                      'installmentsValue',
                      installments[value]?.installments_value
                    )
                    formProps.trigger()
                  }}
                />

                <p className="second-offer-consigNcor-edit-form__disclaimer">
                  Taxa de {formatters.number.toPercent(interestRateMonthly, 2)}{' '}
                  a.m.
                </p>
              </>
            }
          />
        </div>

        <If
          condition={['editing', 'sending'].includes(installmentStatus)}
          renderIf={
            <IdsMainButton
              isLoading={isFormSending}
              data-testid="ok-edit-installments-btn"
              onClick={() => onSubmit(setInstallmentStatus)}
              disabled={
                valueStatus === 'editing' ||
                isFormSending ||
                !!errors.paymentOptions?.message
              }
            >
              Ok
            </IdsMainButton>
          }
          renderElse={
            <IdsContextualButton
              data-testid="edit-installments-btn"
              onClick={() => onEditClick(setInstallmentStatus)}
              disabled={valueStatus === 'editing' || isFormSending}
            >
              Editar
            </IdsContextualButton>
          }
        />
      </div>
    </div>
  )
}

export default EditForm
