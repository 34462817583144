import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { fgtsCallback } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './second-fgts-no-edit.scss'

const FGTS = (props) => {
  const { number } = formatters
  const {
    currentProposal: {
      interestRateMonthly,
      title,
      productCode,
      buttonAriaLabel,
      buttonTrackingName,
      secondOfferHighlights
    }
  } = props

  return (
    <div>
      <div className="second-offer-card__wrapper second-fgts-no-edit">
        <div className="second-offer-card__header">
          <p className="second-offer-card__title --fixed-height">{title}</p>
        </div>
        <div className="second-offer-card__main-content">
          <div className="second-offer-card__info">
            <p className="second-offer-card__info-title">
              Valor do empréstimo de
            </p>
            <p className="second-fgts-no-edit__installments">
              até 97% &nbsp;
              <span className="second-offer-card__info-title --bold">
                do seu saldo
              </span>
            </p>
          </div>
          <div className="second-offer-card__info">
            <p className="second-offer-card__info-title">
              Condições de pagamento
            </p>
            <p className="second-fgts-no-edit__installments --condition">
              sem parcela por mês
            </p>
          </div>
          <div className="second-offer-card__info">
            <p className="second-fgts-no-edit__disclaimer">
              Com taxa de {number.toPercent(interestRateMonthly, 2)} ao mês
            </p>
          </div>

          <AdditionalData list={secondOfferHighlights} />
        </div>

        <div className="second-offer-card__final-content">
          <ButtonContinue
            {...props}
            buttonText="continuar"
            callback={fgtsCallback}
            ariaLabel={buttonAriaLabel}
            id={`second-offer-${productCode}-btn`}
            dataTestid={'secondary-cold-offer-fgts-btn'}
            buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
          />

          <Disclaimer />
        </div>
      </div>
    </div>
  )
}

export default FGTS
