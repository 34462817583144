import { useState } from 'react'

import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { defaultCallback } from '@itau-loans-www/shopping/src/hooks'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import EditForm from './components/EditForm'

const ConsigCondition = (props) => {
  const { currentProposal } = props
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    heroOfferHighlights,
    buttonTrackingName
  } = currentProposal

  return (
    <div>
      <div className="hero-offer__header">
        <p className="hero-offer__title">{title}</p>
      </div>

      <div className="hero-offer__content">
        <div className="hero-offer__value-container">
          <EditForm
            setCardButtonDisabled={setIsButtonDisabled}
            currentProposal={currentProposal}
          />
        </div>

        <AdditionalData list={heroOfferHighlights} />
        <div className="hero-offer__buttons-wrapper">
          <ButtonContinue
            {...props}
            buttonTracking={buttonTrackingName}
            callback={defaultCallback}
            buttonText={buttonText}
            ariaLabel={buttonAriaLabel}
            id={`hero-offer-${productCode}-btn`}
            dataTestid={`hero-offer-consig-btn`}
            disabled={isButtonDisabled}
          />
        </div>

        <Disclaimer />
      </div>
    </div>
  )
}

export default ConsigCondition
