import Shimmer from 'components/Shimmer'

const SkeletonLoaderInput = () => (
  <>
    <Shimmer height="70px" />
    <Shimmer margin="8px 0 0 0" width="40%" height="18px" />
  </>
)

const SkeletonLoaderInfo = () => (
  <>
    <Shimmer margin="20px 0" height="45px" />
    <Shimmer margin="20px 0" height="45px" />
  </>
)

export { SkeletonLoaderInput, SkeletonLoaderInfo }
