import { useState } from 'react'

import { IdsActionButton, IdsIcon } from '@ids/react'
import {
  webAppCallback,
  fgtsCallback,
  useLead,
  useProposal
} from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import EditForm from './components/EditForm'
import './hero-fgts.scss'

const FGTSCondition = (props) => {
  const { currentProposal, isWebAppFlux } = props
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const { setOfferModalOpen, setOfferModalContent, offers } = useLead()
  const { setProduct } = useProposal()

  const proposal = offers?.hero ?? currentProposal

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    heroOfferHighlights,
    buttonTrackingName
  } = proposal

  const finishCallback = isWebAppFlux ? webAppCallback : fgtsCallback

  return (
    <div>
      <div className="hero-fgts">
        <div className="hero-offer__header">
          <p className="hero-offer__title">{title}</p>
        </div>

        <div className="hero-offer__content">
          <div className="hero-offer__value-container">
            <EditForm
              proposal={currentProposal}
              setCardButtonDisabled={setIsButtonDisabled}
            />
          </div>
          <AdditionalData list={heroOfferHighlights} />
          <div className="hero-fgts__buttons-wrapper">
            <ButtonContinue
              {...props}
              buttonTracking={buttonTrackingName}
              buttonText={buttonText}
              callback={finishCallback}
              disabled={isButtonDisabled}
              buttonAriaLabel={buttonAriaLabel}
              dataTestid={`hero-offer-fgts-btn`}
              id={`hero-offer-${productCode}-btn`}
            />
            <IdsActionButton
              variant="neutral"
              id={`hero-offer-details-${productCode}-btn`}
              disabled={isButtonDisabled}
              data-testid={`hero-offer-details-fgts-btn`}
              onClick={() => {
                setOfferModalOpen(true)
                setOfferModalContent({
                  productCode,
                  proposal: proposal,
                  callback: () => {
                    finishCallback({
                      proposal: currentProposal,
                      setButtonLoading: () => {},
                      setProduct
                    })
                    setOfferModalOpen(false)
                  }
                })
              }}
            >
              mais detalhes <IdsIcon size="S">arrow_right_base</IdsIcon>
            </IdsActionButton>
          </div>
          <Disclaimer />
        </div>
      </div>
    </div>
  )
}

export default FGTSCondition
