import { IdsIcon, IdsActionButton } from '@ids/react'
import classNames from 'classnames'
import { goTo } from 'utils'
import { resolvePath } from 'utils'

import './mobile-header.scss'

const MobileHeader = ({ isBrandColor }) => {
  const path = resolvePath('home')

  const HandleClick = () => {
    return goTo(path)
  }

  return (
    <div
      className={classNames('mobile-header', {
        'mobile-header--brand': isBrandColor
      })}
    >
      <IdsActionButton
        variant="neutral"
        data-testId="header-btn"
        onClick={HandleClick}
      >
        <IdsIcon size="M">fechar</IdsIcon>
      </IdsActionButton>
    </div>
  )
}

export default MobileHeader
