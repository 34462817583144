import { useState } from 'react'

import { defaultCallback, useLead } from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import EditForm from './components/EditForm'
import './second-refin.scss'

const Refin = (props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const { offers } = useLead()

  const { currentProposal, offerIdx } = props

  const proposal =
    offers?.others && offers?.others[offerIdx]
      ? offers?.others[offerIdx]
      : currentProposal

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    buttonTrackingName
  } = proposal

  return (
    <div className="second-offer-card__wrapper second-refin">
      <div>
        <div className="second-offer-card__header">
          <p className="second-offer-card__title --fixed-height">{title}</p>
        </div>
        <EditForm
          offerIdx={offerIdx}
          proposal={proposal}
          setCardButtonDisabled={setIsButtonDisabled}
        />
      </div>

      <div>
        <div className="second-offer-card__final-content">
          <ButtonContinue
            {...props}
            buttonText={buttonText}
            callback={defaultCallback}
            disabled={isButtonDisabled}
            ariaLabel={buttonAriaLabel}
            id={`second-offer-${productCode}-btn`}
            dataTestid="secondary-offer-refin-btn"
            buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
          />
          <p className="card-disclaimer">
            *Proposta sujeita à análise de crédito
          </p>
        </div>
      </div>
    </div>
  )
}

export default Refin
