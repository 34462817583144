import { IdsContextualButton, IdsMainButton } from '@ids/react'
import { If, Input } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import {
  SkeletonLoaderInput,
  SkeletonLoaderInfo
} from '../../../../../../components/SkeletonLoader'
import { useEditForm } from './hooks'
import './refin-edit-form.scss'

const EditForm = ({ proposal, setCardButtonDisabled }) => {
  const { offers } = useLead()
  const currentProposal = offers?.hero ?? proposal

  const { maxLoanValue, minLoanValue } = currentProposal

  const {
    onSubmit,
    isFormSending,
    isEditing,
    requestedDefaultValue,
    control,
    onEditClick,
    isValid,
    isToRerender
  } = useEditForm({
    proposal,
    setCardButtonDisabled
  })

  return (
    <div className="refin-edit-form">
      <div className="refin-edit-form__value-wrapper">
        <div className="refin-edit-form__field">
          <If
            condition={isFormSending}
            renderIf={<SkeletonLoaderInput />}
            renderElse={
              <>
                <Input.Currency
                  id="value"
                  label="Valor adicional na sua conta"
                  name="value"
                  type="currency"
                  shouldResetOn={isToRerender}
                  disabled={isFormSending || !isEditing}
                  defaultValue={requestedDefaultValue}
                  rules={{
                    min: minLoanValue,
                    max: maxLoanValue
                  }}
                  control={control}
                />

                <p className="refin-edit-form__disclaimer">
                  Liberado até {formatters.number.toCurrency(maxLoanValue)}
                </p>
              </>
            }
          />
        </div>
        <If
          condition={!isEditing}
          renderIf={
            <IdsContextualButton
              id="edit-btn"
              data-testid="edit-value-btn"
              variant="secondary"
              onClick={onEditClick}
              disabled={isFormSending}
            >
              Editar
            </IdsContextualButton>
          }
          renderElse={
            <IdsMainButton
              id="edit-submit-btn"
              data-testid="ok-edit-value-btn"
              onClick={onSubmit}
              isLoading={isFormSending}
              disabled={isFormSending || !isValid}
            >
              Ok
            </IdsMainButton>
          }
        />
      </div>
      <If
        condition={isFormSending}
        renderIf={<SkeletonLoaderInfo />}
        renderElse={
          <div className="refin-edit-form__info">
            <p className="refin-edit-form__info-title">
              Condições de pagamento
            </p>
            <strong className="refin-edit-form__info-value">
              Taxas de até 3,61%
            </strong>
          </div>
        }
      />
    </div>
  )
}

export default EditForm
