import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { fgtsCallback } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './hero-fgts-no-edit.scss'

const FGTSCondition = (props) => {
  const {
    currentProposal: {
      interestRateMonthly,
      buttonTrackingName,
      title,
      productCode,
      buttonAriaLabel,
      heroOfferHighlights,
      errorType
    }
  } = props

  const customButtonText =
    errorType === 'not_allowed' || errorType === 'error'
      ? 'tentar novamente'
      : 'continuar'

  return (
    <div>
      <div className="hero-fgts-no-edit">
        <div className="hero-offer__header">
          <p className="hero-offer__title">{title}</p>
        </div>

        <div className="hero-offer__content">
          <div className="hero-fgts-no-edit__info">
            <div className="hero-offer__value-block">
              <p className="hero-offer__subtitle">valor do empréstimo de</p>
              <p className="hero-fgts-no-edit__highlight">
                <span>até 97%</span>
                &nbsp;
                <span className="hero-fgts-no-edit__installments-text">
                  do seu saldo
                </span>
              </p>
            </div>

            <div className="hero-offer__value-block">
              <p className="hero-offer__subtitle">condições de pagamento</p>
              <p className="hero-fgts-no-edit__highlight --condition">
                sem parcela por mês
              </p>
            </div>
          </div>

          <div className="hero-offer__value-block">
            <p className="hero-fgts-no-edit__tax">
              com taxa de {formatters.number.toPercent(interestRateMonthly, 2)}{' '}
              ao mês
            </p>
          </div>

          <AdditionalData list={heroOfferHighlights} />

          <ButtonContinue
            {...props}
            buttonTracking={buttonTrackingName}
            buttonText={customButtonText}
            callback={fgtsCallback}
            dataTestid={`hero-offer-fgts-btn`}
            buttonAriaLabel={buttonAriaLabel}
            id={`hero-offer-${productCode}-btn`}
          />

          <Disclaimer />
        </div>
      </div>
    </div>
  )
}

export default FGTSCondition
