const Bullets = [
  {
    icon: 'consorcio_outline',
    title: 'Mais barato',
    description: 'Realize seu sonho com facilidade, sem juros e sem entrada'
  },
  {
    icon: 'celular_pre_pago_outline',
    title: 'Acessível',
    description: 'Fácil de contratar pelo app, na agência ou com especialistas'
  },
  {
    icon: 'favorito_outline',
    title: 'Garantido',
    description: 'Somos um dos consórcios que mais contempla'
  },
  {
    icon: 'presente_outline',
    title: 'Flexível',
    description: 'Dê um lance nos sorteios mensais pra acelerar seu sonho'
  },
  {
    icon: 'juros',
    title: 'Descontos',
    description: 'Descontos exclusivos em seguros auto e residencial'
  }
]

export default Bullets
