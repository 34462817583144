import { useState } from 'react'

import { IdsActionButton, IdsIcon } from '@ids/react'
import { defaultCallback, useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import Disclaimer from '../../../../components/Disclaimer'
import EditForm from './components/EditForm'
import './second-consigNcor.scss'

const ConsigNcor = (props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const { setOfferModalOpen, setOfferModalContent, offers } = useLead()
  const { setProduct } = useProposal()

  const { currentProposal, offerIdx } = props
  const proposal =
    offers?.others && offers?.others[offerIdx]
      ? offers?.others[offerIdx]
      : currentProposal

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    buttonTrackingName,
    loanLabel
  } = proposal

  return (
    <div className="second-offer-card__wrapper second-consigNcor">
      <div>
        <div className="second-offer-card__header">
          <p className="second-offer-card__title --smaller --fixed-height">
            {title}
          </p>
        </div>

        <EditForm
          offerIdx={offerIdx}
          setCardButtonDisabled={setIsButtonDisabled}
          currentProposal={proposal}
          loanLabel={loanLabel}
        />
      </div>

      <div className="second-offer-card__final-content">
        <ButtonContinue
          {...props}
          buttonText={buttonText}
          callback={defaultCallback}
          disabled={isButtonDisabled}
          ariaLabel={buttonAriaLabel}
          id={`second-offer-${productCode}-btn`}
          dataTestid={'secondary-offer-consig_ncor-btn'}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
        />
        <IdsActionButton
          variant="neutral"
          disabled={isButtonDisabled}
          className="second-consigNcor__details-btn"
          id={`second-offer-details-${productCode}-btn`}
          data-testid={'secondary-offer-details-consig_ncor-btn'}
          onClick={() => {
            setOfferModalOpen(true)
            setOfferModalContent({
              ...proposal,
              callback: () => {
                defaultCallback({
                  proposal: proposal,
                  setButtonLoading: () => {},
                  setProduct
                })
                setOfferModalOpen(false)
              }
            })
          }}
        >
          mais detalhes <IdsIcon size="S">arrow_right_base</IdsIcon>
        </IdsActionButton>

        <Disclaimer />
      </div>
    </div>
  )
}

export default ConsigNcor
