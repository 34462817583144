import { useEffect, useState } from 'react'

const defaultCountDown = 60

export const useTokenAgain = ({ sendSms }) => {
  const [countdown, setCountdown] = useState(defaultCountDown)

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    }
  }, [countdown, setCountdown])

  const send = () => {
    sendSms()
    setCountdown(defaultCountDown)
  }

  return {
    countdown,
    send
  }
}
