import { IdsContextualButton, IdsMainButton } from '@ids/react'
import { If, Input } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import {
  SkeletonLoaderInput,
  SkeletonLoaderInfo
} from '../../../../../../components/SkeletonLoader'
import './consig-edit-form.scss'
import { useEditForm } from './hooks'

const EditForm = ({ setCardButtonDisabled, currentProposal }) => {
  const { offers } = useLead()

  const proposal = offers?.hero ?? currentProposal

  const {
    maxLoanInstallmentsValue,
    minLoanInstallmentsValue,
    maxLoanValue,
    minLoanValue,
    interestRateMonthly,
    installmentsNumber
  } = proposal

  const {
    onSubmit,
    isFormSending,
    valueStatus,
    setValueStatus,
    installmentStatus,
    setInstallmentStatus,
    installmentDefaultValue,
    requestedDefaultValue,
    onEditClick,
    control,
    isValid,
    errors,
    isToRerender
  } = useEditForm({
    proposal,
    setCardButtonDisabled
  })

  return (
    <div className="consig-edit-form">
      <div className="consig-edit-form__value-wrapper">
        <div className="consig-edit-form__field">
          <If
            condition={isFormSending}
            renderIf={<SkeletonLoaderInput />}
            renderElse={
              <>
                <Input.Currency
                  id="value"
                  label="Você vai receber"
                  name="value"
                  type="currency"
                  shouldResetOn={isToRerender}
                  disabled={valueStatus === 'saved' || isFormSending}
                  defaultValue={requestedDefaultValue}
                  rules={{
                    min: minLoanValue,
                    max: maxLoanValue
                  }}
                  control={control}
                />

                <p className="consig-edit-form__disclaimer">
                  Liberado até {formatters.number.toCurrency(maxLoanValue)}
                </p>
              </>
            }
          />
        </div>
        <If
          condition={['editing', 'sending'].includes(valueStatus)}
          renderIf={
            <IdsMainButton
              onClick={() => onSubmit(setValueStatus)}
              isLoading={isFormSending}
              data-testid="ok-edit-value-btn"
              disabled={
                installmentStatus === 'editing' ||
                isFormSending ||
                !!errors.value
              }
            >
              Ok
            </IdsMainButton>
          }
          renderElse={
            <IdsContextualButton
              data-testid="edit-value-btn"
              onClick={() => onEditClick(setValueStatus)}
              disabled={installmentStatus === 'editing' || isFormSending}
            >
              Editar
            </IdsContextualButton>
          }
        />
      </div>
      <div className="consig-edit-form__value-wrapper">
        <div className="consig-edit-form__field">
          <If
            condition={isFormSending}
            renderIf={<SkeletonLoaderInput />}
            renderElse={
              <>
                <Input.Currency
                  id="installmentsValue"
                  label="Valor da parcela"
                  type="currency"
                  name="installmentsValue"
                  shouldResetOn={isToRerender}
                  disabled={installmentStatus === 'saved' || isFormSending}
                  defaultValue={installmentDefaultValue}
                  rules={{
                    min: minLoanInstallmentsValue,
                    max: maxLoanInstallmentsValue
                  }}
                  control={control}
                />

                <p className="consig-edit-form__disclaimer">
                  Taxas de {formatters.number.toPercent(interestRateMonthly, 2)}{' '}
                  a.m.
                </p>
              </>
            }
          />
        </div>
        <If
          condition={['editing', 'sending'].includes(installmentStatus)}
          renderIf={
            <IdsMainButton
              onClick={() => onSubmit(setInstallmentStatus)}
              isLoading={isFormSending}
              data-testid="ok-edit-installments-btn"
              disabled={valueStatus === 'editing' || isFormSending || !isValid}
            >
              Ok
            </IdsMainButton>
          }
          renderElse={
            <IdsContextualButton
              onClick={() => onEditClick(setInstallmentStatus)}
              data-testid="edit-installments-btn"
              disabled={valueStatus === 'editing' || isFormSending}
            >
              Editar
            </IdsContextualButton>
          }
        />
      </div>
      <If
        condition={isFormSending}
        renderIf={<SkeletonLoaderInfo />}
        renderElse={
          <div className="consig-edit-form__info">
            <p className="consig-edit-form__info-title">
              Condições de pagamento
            </p>
            <strong className="consig-edit-form__info-value">
              {installmentsNumber} parcelas
            </strong>
          </div>
        }
      />
    </div>
  )
}

export default EditForm
