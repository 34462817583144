import { IdsContextualButton, IdsMainButton } from '@ids/react'
import { If, Input } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import { SkeletonLoaderInput } from 'components/Cards/components/SkeletonLoader/index'
import Shimmer from 'components/Shimmer'

import './fgts-edit-form.scss'
import { useEditForm } from './hooks'

const EditForm = ({ proposal, setCardButtonDisabled }) => {
  const { offers } = useLead()
  const currentProposal = offers?.hero ?? proposal

  const { maxLoanValue, minLoanValue, installmentsNumber } = currentProposal

  const {
    onSubmit,
    isFormSending,
    isEditing,
    requestedDefaultValue,
    control,
    onEditClick,
    isValid,
    isToRerender
  } = useEditForm({
    proposal,
    setCardButtonDisabled
  })

  return (
    <div className="fgts-edit-form">
      <div className="fgts-edit-form__value-wrapper">
        <div className="fgts-edit-form__field">
          <If
            condition={isFormSending}
            renderIf={<SkeletonLoaderInput />}
            renderElse={
              <>
                <Input.Currency
                  id="value"
                  label="Você vai receber"
                  name="value"
                  type="currency"
                  shouldResetOn={isToRerender}
                  disabled={isFormSending || !isEditing}
                  defaultValue={requestedDefaultValue}
                  rules={{
                    min: minLoanValue,
                    max: maxLoanValue
                  }}
                  control={control}
                />

                <p className="fgts-edit-form__disclaimer">
                  Valor liberado {formatters.number.toCurrency(maxLoanValue)}
                </p>
              </>
            }
          />
        </div>
        <If
          condition={!isEditing}
          renderIf={
            <IdsContextualButton
              id="edit-btn"
              data-testid="edit-value-btn"
              onClick={onEditClick}
              disabled={isFormSending}
            >
              Editar
            </IdsContextualButton>
          }
          renderElse={
            <IdsMainButton
              id="edit-submit-btn"
              data-testid="ok-edit-value-btn"
              onClick={onSubmit}
              isLoading={isFormSending}
              disabled={isFormSending || !isValid}
            >
              Ok
            </IdsMainButton>
          }
        />
      </div>
      <If
        condition={isFormSending}
        renderIf={<Shimmer margin="32px 0 18px 0" height="43px" />}
        renderElse={
          <div className="fgts-edit-form__info">
            <p className="fgts-edit-form__info-title">Saques antecipados</p>
            <strong className="fgts-edit-form__info-value">
              {installmentsNumber} saque{installmentsNumber > 1 ? 's' : ''}
            </strong>
          </div>
        }
      />
    </div>
  )
}

export default EditForm
