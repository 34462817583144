import { useState, useEffect } from 'react'

import { IdsContextualButton } from '@ids/react'
import { If } from '@itau-loans-www/shopping/src/components'
import simulationAllProductsShopping from './constants'
import { segment } from 'utils'

import Card from '../components/ProductsCard'
import './offer-all-products.scss'

const Products = () => {
  const [filteredCards, setFilteredCards] = useState(
    simulationAllProductsShopping.slice(0, 4)
  )
  const [nextOpenedIndex, setNextOpenedIndex] = useState(8)
  const [tracked, setTracked] = useState([])

  useEffect(() => {
    filteredCards.map(({ trackName }, index) => {
      if (!tracked.includes(trackName)) {
        segment.pageView({
          data: window,
          payload: {
            productName: trackName,
            type: 'Cross sell',
            order: index
          }
        })

        setTracked((state) => [...state, trackName])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCards])

  return (
    <section id="outros-produtos" className="offer-all-products">
      <div className="offer-all-products__content">
        <p className="offer-all-products__title">
          O Itaú pode te ajudar de outras formas
        </p>
        <p className="offer-all-products__text">
          São produtos de crédito, financiamento, cartões, seguros e muito mais
        </p>
        <div className="offer-all-products__gridcard">
          {filteredCards.map(
            ({ title, text, icon, link, textLink, trackName }, idx) => (
              <div key={title}>
                <Card
                  title={title}
                  text={text}
                  icon={icon}
                  link={link}
                  textLink={textLink}
                  trackName={trackName}
                  index={idx}
                />
              </div>
            )
          )}
        </div>
        <If
          condition={
            filteredCards.length < simulationAllProductsShopping.length
          }
          renderIf={
            <IdsContextualButton
              className="offer-all-products__button"
              onClick={() => {
                setFilteredCards(
                  simulationAllProductsShopping.slice(0, nextOpenedIndex)
                )
                setNextOpenedIndex((state) => state + 4)
              }}
            >
              Mais soluções
            </IdsContextualButton>
          }
          renderElse={
            <IdsContextualButton
              className="offer-all-products__button"
              onClick={() => {
                setFilteredCards(simulationAllProductsShopping.slice(0, 4))
                setNextOpenedIndex(6)
              }}
            >
              Menos soluções
            </IdsContextualButton>
          }
        />
      </div>
    </section>
  )
}

export default Products
