import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks/useOnContinue'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './credit-card-offer.scss'

const CreditCardOffer = (props) => {
  const { currentProposal } = props

  const {
    title,
    highlights,
    productCode,
    buttonText,
    buttonTrackingName,
    image
  } = currentProposal

  return (
    <div>
      <div className="hero-offer__header">
        <p className="hero-offer__title">{title}</p>
      </div>

      <div className="credit-card-offer">
        <figure className="credit-card-offer__img-wrapper">
          <img
            src={image}
            alt={title}
            title={title}
            className="img"
            width="133"
            height="120"
          />
        </figure>

        <AdditionalData list={highlights} />

        <ButtonContinue
          {...props}
          buttonTracking={buttonTrackingName}
          buttonText={buttonText}
          callback={finishProposalCallback}
          id={`hero-offer-${productCode}-btn`}
          dataTestid="hero-offer-credit-card-offer-btn"
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default CreditCardOffer
