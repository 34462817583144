import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useMapper, useLead } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'

export const useEditForm = ({ setCardButtonDisabled, proposal }) => {
  const { changeOffers } = useLead()
  const [isFormSending, setIsFormSending] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { mapSimulationsWhenEdit } = useMapper()
  const { maxLoanValue, offerUuid, requestedLoanValue } = proposal
  const [isToRerender, setIsToRerender] = useState(false)

  const value = requestedLoanValue > 0 ? requestedLoanValue : maxLoanValue
  const [requestedDefaultValue, setRequestedDefaultValue] = useState(value)
  const {
    control,
    getValues,
    setValue,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: requestedDefaultValue
    }
  })

  const onEditClick = () => {
    setIsEditing(true)
    setCardButtonDisabled(true)
  }

  // Por limitações do Currency Input do DS
  // para alterar o valor fora do input
  // é necessário renderizar ele novamente
  const updateValues = (value) =>
    new Promise((resolve) => {
      setValue('value', value)

      setRequestedDefaultValue(value)
      setIsToRerender(true)
      resolve()
    })

  const onSubmit = async () => {
    try {
      setIsFormSending(true)
      setCardButtonDisabled(true)
      const newData = await http.proposal.editProposalData(
        offerUuid,
        getValues()
      )
      setIsFormSending(false)

      await updateValues(newData?.requested_loan_value)
      setIsToRerender(false)

      const formattedData = mapSimulationsWhenEdit(newData)

      changeOffers({
        hero: formattedData
      })

      setIsEditing(false)
      setCardButtonDisabled(false)
    } catch {
      setCardButtonDisabled(false)
    }
  }

  return {
    onSubmit,
    isFormSending,
    isEditing,
    requestedDefaultValue,
    onEditClick,
    isToRerender,
    control,
    isValid
  }
}
