import { IdsActionButton, IdsIcon } from '@ids/react'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { useLead, webAppCallback } from '@itau-loans-www/shopping/src/hooks'

import AdditionalData from '../../../../components/AdditionalData'
import ConsorcioModal from '../../../../components/ConsorcioModal'
import Disclaimer from '../../../../components/Disclaimer'
import './hero-consorcio.scss'

const ConsorcioCondition = (props) => {
  const { currentProposal } = props

  const { setOfferModalOpen } = useLead()

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    heroOfferHighlights
  } = currentProposal

  return (
    <div>
      <p className="hero-consorcio__title">{title}</p>
      <span className="hero-consorcio__subtitle">
        No consórcio Itaú, você encontra:
      </span>

      <div className="hero-offer__content">
        <AdditionalData list={heroOfferHighlights} />

        <ButtonContinue
          {...props}
          buttonText={buttonText}
          callback={webAppCallback}
          buttonAriaLabel={buttonAriaLabel}
          id={`hero-offer-${productCode}-btn`}
          dataTestid="default"
        />
        <IdsActionButton
          className="hero-consorcio__info-btn"
          variant="neutral"
          id={`hero-offer-details-${productCode}-btn`}
          data-testid={`hero-offer-details-consortium-btn`}
          onClick={() => {
            setOfferModalOpen(true)
          }}
        >
          mais detalhes <IdsIcon size="S">arrow_right_base</IdsIcon>
        </IdsActionButton>

        <Disclaimer />
      </div>
      <ConsorcioModal />
    </div>
  )
}

export default ConsorcioCondition
