import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { Input } from '@itau-loans-www/shopping/src/components'
import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './hero-cgi.scss'
import { useCGICard } from './useCGICard'

const CgiCondition = (props) => {
  const { currentProposal } = props

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    heroOfferHighlights,
    buttonTrackingName
  } = currentProposal

  const { requestedDefaultValue, control, proposalInfo } = useCGICard({
    currentProposal
  })

  const {
    maxLoanValue,
    minLoanValue,
    initialInstallment,
    interestRateMonthly,
    lastInstallment
  } = proposalInfo

  return (
    <div className="cgi-hero">
      <div className="hero-offer__header">
        <p className="hero-offer__title">{title}</p>
      </div>
      <div className="hero-offer__content">
        <div className="cgi-hero-form">
          <div className="cgi-hero-form__value-wrapper">
            <div className="cgi-hero-form__field">
              <Input.Currency
                id="value"
                label="valor sugerido"
                name="value"
                type="currency"
                shouldResetOn={false}
                disabled={true}
                defaultValue={requestedDefaultValue}
                rules={{
                  min: minLoanValue,
                  max: maxLoanValue
                }}
                control={control}
              />

              <p className="cgi-hero-form__disclaimer">
                De {formatters.number.toCurrency(minLoanValue, 0)} até{' '}
                {formatters.number.toCurrency(maxLoanValue, 0)}
              </p>
            </div>
          </div>
          <div className="cgi-hero-form__info">
            <p className="cgi-hero-form__info-title">primeira parcela</p>
            <div className="cgi-hero-form__info-values">
              <strong className="cgi-hero-form__info-value">
                R$ {formatters.number.toFloat(initialInstallment)}
              </strong>
              Taxa de {formatters.number.toPercent(interestRateMonthly, 2)} a.m.
            </div>
          </div>
          <div className="cgi-hero-form__info">
            <p className="cgi-hero-form__info-title">ultima parcela</p>
            <strong className="cgi-hero-form__info-value">
              R$ {formatters.number.toFloat(lastInstallment)}
            </strong>
          </div>
        </div>

        <AdditionalData list={heroOfferHighlights} />

        <ButtonContinue
          {...props}
          buttonTracking={buttonTrackingName}
          callback={finishProposalCallback}
          buttonText={buttonText}
          ariaLabel={buttonAriaLabel}
          id={`hero-offer-${productCode}-btn`}
          dataTestid="hero-offer-cgi-btn"
          disabled={false}
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default CgiCondition
