import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './second-consig-cold.scss'

const ConsigCold = (props) => {
  const { currentProposal } = props

  const {
    title,
    highlights,
    buttonText,
    productCode,
    subtitleBig,
    buttonAriaLabel,
    buttonTrackingName
  } = currentProposal

  return (
    <div className="second-offer-card__wrapper second-consig-cold">
      <div className="second-offer-card__header">
        <p className="second-offer-card__title">{title}</p>
      </div>
      <div className="second-consig-cold__highlights-container second-offer-card__main-content">
        <div className="second-offer-card__info">
          <p className="second-consig-cold__subtitle">{subtitleBig}</p>
        </div>

        <AdditionalData list={highlights} />
      </div>

      <div className="second-offer-card__final-content">
        <ButtonContinue
          {...props}
          buttonText={buttonText}
          ariaLabel={buttonAriaLabel}
          callback={finishProposalCallback}
          id={`second-offer-${productCode}-btn`}
          dataTestid={'secondary-offer-itau_account-btn'}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default ConsigCold
