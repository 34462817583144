import { useForm } from 'react-hook-form'

import { useLead } from '@itau-loans-www/shopping/src/hooks'

export const useCGICard = ({ currentProposal }) => {
  const { offers } = useLead()

  const { maxLoanValue, requestedLoanValue } = currentProposal

  const proposalInfo = offers?.hero ?? currentProposal

  const value = requestedLoanValue > 0 ? requestedLoanValue : maxLoanValue
  const {
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value
    }
  })

  return {
    requestedDefaultValue: value,
    control,
    isValid,
    proposalInfo
  }
}
