import { IdsContextualButton, IdsMainButton } from '@ids/react'
import { Input, If, Select } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import { SkeletonLoaderInput } from '../../../../../../components/SkeletonLoader'
import './consigNcor-edit-form.scss'
import { useEditForm } from './hooks'

const EditForm = ({
  setCardButtonDisabled,
  currentProposal,
  loanLabel = 'Valor adicional na sua conta'
}) => {
  const { offers } = useLead()

  const proposal = offers?.hero ?? currentProposal

  const { maxLoanValue, minLoanValue, additionalData, interestRateMonthly } =
    proposal

  const { installments, paymentOptions } = additionalData

  const {
    onSubmit,
    isFormSending,
    valueStatus,
    setValueStatus,
    installmentStatus,
    setInstallmentStatus,
    requestedDefaultValue,
    onEditClick,
    control,
    errors,
    isToRerender,
    formProps
  } = useEditForm({
    proposal,
    setCardButtonDisabled,
    installments,
    paymentOptions
  })

  return (
    <div className="consigNcor-edit-form">
      <div className="consigNcor-edit-form__value-wrapper">
        <div className="consigNcor-edit-form__field">
          <If
            condition={isFormSending}
            renderIf={<SkeletonLoaderInput />}
            renderElse={
              <>
                <Input.Currency
                  id="value"
                  label={loanLabel}
                  name="value"
                  type="currency"
                  shouldResetOn={isToRerender}
                  disabled={valueStatus === 'saved' || isFormSending}
                  defaultValue={requestedDefaultValue}
                  rules={{
                    min: minLoanValue,
                    max: maxLoanValue
                  }}
                  control={control}
                />

                <p className="consigNcor-edit-form__disclaimer">
                  Máximo de {formatters.number.toCurrency(maxLoanValue)}
                </p>
              </>
            }
          />
        </div>
        <If
          condition={['editing', 'sending'].includes(valueStatus)}
          renderIf={
            <IdsMainButton
              id="edit-submit-btn"
              onClick={() => onSubmit(setValueStatus)}
              data-testid="ok-edit-value-btn"
              isLoading={isFormSending}
              disabled={
                installmentStatus === 'editing' ||
                isFormSending ||
                !!errors.value
              }
            >
              Ok
            </IdsMainButton>
          }
          renderElse={
            <IdsContextualButton
              id="edit-btn"
              data-testid="edit-value-btn"
              onClick={() => onEditClick(setValueStatus)}
              disabled={installmentStatus === 'editing' || isFormSending}
            >
              Editar
            </IdsContextualButton>
          }
        />
      </div>

      <div className="consigNcor-edit-form__value-wrapper --select">
        <div className="consigNcor-edit-form__field">
          <If
            condition={isFormSending}
            renderIf={<SkeletonLoaderInput />}
            renderElse={
              <>
                <Select
                  label="Condições de pagamento"
                  name="paymentOptions"
                  control={control}
                  options={paymentOptions}
                  formProps={formProps}
                  disabled={installmentStatus === 'saved' || isFormSending}
                  onChange={({ value }) => {
                    formProps.setValue(
                      'installmentsNumber',
                      installments[value]?.installments_number
                    )
                    formProps.setValue(
                      'installmentsValue',
                      installments[value]?.installments_value
                    )
                    formProps.trigger()
                  }}
                />

                <p className="consigNcor-edit-form__disclaimer">
                  Taxa de {formatters.number.toPercent(interestRateMonthly, 2)}{' '}
                  a.m.
                </p>
              </>
            }
          />
        </div>
        <If
          condition={['editing', 'sending'].includes(installmentStatus)}
          renderIf={
            <IdsMainButton
              data-testid="ok-edit-installments-btn"
              id="edit-submit-installments-btn"
              onClick={() => onSubmit(setInstallmentStatus)}
              isLoading={isFormSending}
              disabled={
                valueStatus === 'editing' ||
                isFormSending ||
                !!errors.paymentOptions?.message
              }
            >
              Ok
            </IdsMainButton>
          }
          renderElse={
            <IdsContextualButton
              id="edit-btn-installments"
              data-testid="edit-installments-btn"
              onClick={() => onEditClick(setInstallmentStatus)}
              disabled={valueStatus === 'editing' || isFormSending}
            >
              Editar
            </IdsContextualButton>
          }
        />
      </div>
    </div>
  )
}

export default EditForm
