import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { Input } from '@itau-loans-www/shopping/src/components'
import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './second-offer-cgi.scss'
import { useCGICard } from './useCGICard'

const Cgi = (props) => {
  const { currentProposal } = props

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    buttonTrackingName,
    secondOfferHighlights
  } = currentProposal

  const { requestedDefaultValue, control, proposalInfo } = useCGICard({
    currentProposal
  })

  const {
    maxLoanValue,
    minLoanValue,
    initialInstallment,
    interestRateMonthly,
    lastInstallment
  } = proposalInfo

  return (
    <div className="second-offer-card__wrapper">
      <div className="second-offer-card__header">
        <p className="second-offer-card__title --fixed-height">{title}</p>
      </div>

      <div className="second-offer-card__main-content">
        <div className="cgi-second-form">
          <div className="cgi-second-form__value-wrapper">
            <div className="cgi-second-form__field">
              <Input.Currency
                id="value"
                label="valor sugerido do empréstimo"
                name="value"
                type="currency"
                shouldResetOn={false}
                disabled={true}
                defaultValue={requestedDefaultValue}
                rules={{
                  min: minLoanValue,
                  max: maxLoanValue
                }}
                control={control}
              />

              <p className="cgi-second-form__disclaimer">
                De {formatters.number.toCurrency(minLoanValue, 0)} até{' '}
                {formatters.number.toCurrency(maxLoanValue, 0)}
              </p>
            </div>
          </div>
          <div className="cgi-second-form__info">
            <p className="cgi-second-form__info-title">primeira parcela</p>
            <div className="cgi-second-form__info-values">
              <strong className="cgi-second-form__info-value">
                R$ {formatters.number.toFloat(initialInstallment)}
              </strong>
              Taxa de {formatters.number.toPercent(interestRateMonthly, 2)} a.m.
            </div>
          </div>
          <div className="cgi-second-form__info">
            <p className="cgi-second-form__info-title">ultima parcela</p>
            <strong className="cgi-second-form__info-value">
              R$ {formatters.number.toFloat(lastInstallment)}
            </strong>
          </div>
        </div>

        <AdditionalData list={secondOfferHighlights} />
      </div>

      <div className="second-offer-card__final-content">
        <ButtonContinue
          {...props}
          buttonText={buttonText}
          ariaLabel={buttonAriaLabel}
          callback={finishProposalCallback}
          dataTestid={'secondary-offer-cgi-btn'}
          id={`second-offer-${productCode}-btn`}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
          disabled={false}
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default Cgi
