import { useState } from 'react'

import { IdsActionButton, IdsIcon } from '@ids/react'
import {
  fgtsCallback,
  webAppCallback,
  useLead,
  useProposal
} from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import EditForm from './components/EditForm'
import './second-fgts.scss'

const FGTS = (props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const { offers } = useLead()

  const { currentProposal, offerIdx, isWebAppFlux } = props

  const proposal =
    offers?.others && offers?.others[offerIdx]
      ? offers?.others[offerIdx]
      : currentProposal

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    buttonTrackingName,
    secondOfferHighlights
  } = proposal

  const { setOfferModalOpen, setOfferModalContent } = useLead()
  const { setProduct } = useProposal()

  const finishCallback = isWebAppFlux ? webAppCallback : fgtsCallback

  return (
    <div className="second-offer-card__wrapper second-fgts">
      <div>
        <div className="second-offer-card__header">
          <p className="second-offer-card__title --fixed-height">{title}</p>
        </div>
        <EditForm
          offerIdx={offerIdx}
          proposal={proposal}
          setCardButtonDisabled={setIsButtonDisabled}
        />
      </div>

      <div className="second-offer-card__final-content">
        <AdditionalData list={secondOfferHighlights} />

        <ButtonContinue
          {...props}
          buttonText={buttonText}
          callback={finishCallback}
          ariaLabel={buttonAriaLabel}
          disabled={isButtonDisabled}
          dataTestid="secondary-offer-fgts-btn"
          id={`second-offer-${productCode}-btn`}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
        />
        <IdsActionButton
          variant="neutral"
          data-testid="secondary-offer-details-fgts-btn"
          id={`second-offer-details-${productCode}-btn`}
          disabled={isButtonDisabled}
          onClick={() => {
            setOfferModalOpen(true)
            setOfferModalContent({
              productCode,
              proposal: proposal,
              callback: () => {
                finishCallback({
                  proposal: proposal,
                  setButtonLoading: () => {},
                  setProduct
                })
                setOfferModalOpen(false)
              }
            })
          }}
        >
          mais detalhes <IdsIcon size="S">arrow_right_base</IdsIcon>
        </IdsActionButton>
        <Disclaimer />
      </div>
    </div>
  )
}

export default FGTS
