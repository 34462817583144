import { useEffect } from 'react'

import { formatters } from 'utils'
import { segment } from 'utils'

import CardCGI from './components/CardCGI'
import './locked-proposals.scss'

const LockedProposals = ({ proposals = [] }) => {
  const { productCode, interestRateMonthly, maxInstallmentsNumber } =
    proposals[0]

  useEffect(() => {
    segment.pageView({
      data: window,
      payload: {
        productName: productCode,
        type: 'Desbloqueio'
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="locked-proposals">
      <div className="locked-proposals__content">
        <div className="locked-proposals__col">
          <p className="locked-proposals__title">
            {`Outras ofertas podem estar disponíveis para você`}
          </p>
          <ul className="locked-proposals__unlock-list">
            <li className="locked-proposals__unlock-list-item">
              {`Se você tem um imóvel quitado, clique em “desbloquear” e libere essa oferta`}
            </li>
          </ul>
        </div>
        <div className="-second-col-card-alone">
          <CardCGI
            productCode={productCode}
            headerTitle="Empréstimo de até"
            headerSubtitle={'60% do valor do seu imóvel'}
            footerTitle="Crédito com garantia de imóvel"
            footerSubtitle={`Prazo de até ${maxInstallmentsNumber / 12} anos`}
            footerDisclaimer={`com taxas a partir de ${formatters.number.toPercent(
              interestRateMonthly,
              2
            )}a.m.`}
          />
        </div>
      </div>
    </section>
  )
}

export default LockedProposals
