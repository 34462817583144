import { IdsMainButton, IdsIcon, IdsLoading } from '@ids/react'
import { If } from '@itau-loans-www/shopping/src/components'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { segment } from 'utils'

const ButtonContinue = ({
  id,
  callback,
  currentProposal: proposal,
  isButtonLoading,
  setButtonLoading,
  disabled,
  className,
  buttonText = 'Continuar',
  ariaLabel = 'botão continuar',
  dataTestid,
  isHero = false,
  offerIdx
}) => {
  const { setProduct } = useProposal()
  const productCode = proposal?.productCode

  return (
    <IdsMainButton
      id={id}
      data-testid={dataTestid}
      aria-label={ariaLabel}
      disabled={isButtonLoading || disabled}
      onClick={() => {
        if (setButtonLoading) setButtonLoading(true)
        segment.buttonClick({
          currentButton: {
            innerText: buttonText,
            dataset: { id }
          },
          payload: {
            productName: productCode,
            type: isHero ? 'Hero' : 'Carrossel',
            order: offerIdx
          }
        })

        analytics.track('buttonClick', {
          currentPage: 'vitrine-shopping',
          detail: 'click-intencao-vitrine',
          eventName: 'conversion',
          customLayer: {
            description: isHero ? 'hero' : `carrossel-${offerIdx + 1}`
          }
        })
        callback({ proposal, setButtonLoading, setProduct })
      }}
      className={className}
      full
    >
      <If
        condition={isButtonLoading}
        renderElse={
          <>
            {buttonText}
            <IdsIcon>seta_direita</IdsIcon>
          </>
        }
        renderIf={
          <IdsLoading
            className="wrapper-sendeer__submit-loading"
            aria-live="polite"
            aria-label="aguarde, estamos enviando seus dados..."
            size="XS"
            show={true}
          />
        }
      />
    </IdsMainButton>
  )
}

export default ButtonContinue
