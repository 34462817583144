import { IdsContextualButton, IdsMainButton } from '@ids/react'
import { If, Input } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import Shimmer from 'components/Shimmer'

import { useEditForm } from './hooks'
import './second-fgts-edit-form.scss'

const EditForm = ({ proposal, setCardButtonDisabled, offerIdx }) => {
  const { offers } = useLead()
  const currentProposal =
    offers?.others && offers?.others[offerIdx]
      ? offers?.others[offerIdx]
      : proposal
  const { maxLoanValue, minLoanValue, installmentsNumber } = currentProposal

  const {
    onSubmit,
    isFormSending,
    isEditing,
    control,
    onEditClick,
    isValid,
    isToRerender,
    requestedDefaultValue
  } = useEditForm({
    proposal,
    setCardButtonDisabled,
    offerIdx
  })

  return (
    <div className="second-fgts-edit-form">
      <div className="second-fgts-edit-form__field">
        <If
          condition={isFormSending}
          renderIf={
            <>
              <Shimmer height="70px" />
              <Shimmer margin="8px 0 0 0" width="40%" height="18px" />
            </>
          }
          renderElse={
            <>
              <Input.Currency
                id="value"
                label="Você vai receber"
                name="value"
                type="currency"
                shouldResetOn={isToRerender}
                disabled={isFormSending || !isEditing}
                defaultValue={requestedDefaultValue}
                rules={{
                  min: minLoanValue,
                  max: maxLoanValue
                }}
                control={control}
              />

              <p className="second-fgts-edit-form__disclaimer">
                Valor liberado {formatters.number.toCurrency(maxLoanValue)}
              </p>
            </>
          }
        />
      </div>
      <If
        condition={isFormSending}
        renderIf={<Shimmer margin="16px 0 0 0" width="76px" height="50px" />}
        renderElse={
          <If
            condition={!isEditing}
            renderIf={
              <IdsContextualButton
                onClick={onEditClick}
                disabled={isFormSending}
                data-testid="edit-value-btn"
              >
                Editar
              </IdsContextualButton>
            }
            renderElse={
              <IdsMainButton
                onClick={onSubmit}
                isLoading={isFormSending}
                data-testid="ok-edit-value-btn"
                disabled={isFormSending || !isValid}
              >
                Ok
              </IdsMainButton>
            }
          />
        }
      />
      <If
        condition={isFormSending}
        renderIf={<Shimmer margin="32px 0 18px 0" height="43px" />}
        renderElse={
          <div className="second-fgts-edit-form__info">
            <p className="second-fgts-edit-form__info-title">
              Saques antecipados
            </p>
            <strong className="second-fgts-edit-form__info-value">
              {installmentsNumber} saques
            </strong>
          </div>
        }
      />
    </div>
  )
}

export default EditForm
