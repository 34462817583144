import { IdsMainButton, IdsIcon } from '@ids/react'
import { useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import { If } from '@itau-loans-www/shopping/src/components'

import './cgi-locked-card.scss'
import { trackOnContinue } from './hooks'

const CardCGI = ({
  productCode,
  headerTitle,
  headerSubtitle,
  footerTitle,
  footerSubtitle,
  footerDisclaimer
}) => {
  const isDesktop = useMediaQuery('tablet')

  return (
    <div className="cgi-locked-card">
      <div className="cgi-locked-card__container">
        <div className="cgi-locked-card__header">
          <IdsIcon size="L">desbloqueio_outline</IdsIcon>
          <div className="text-container">
            <p className="cgi-locked-card__header-title">{headerTitle}</p>
            <p
              className="cgi-locked-card__header-subtitle"
              dangerouslySetInnerHTML={{ __html: headerSubtitle }}
            />
          </div>
        </div>

        <div className="cgi-locked-card__footer">
          <div className="cgi-locked-card__footer-content">
            <div className="cgi-locked-card__footer-text">
              <p className="cgi-locked-card__footer-title">{footerTitle}</p>
              <If
                condition={isDesktop}
                renderIf={
                  <p className="cgi-locked-card__footer-subtitle">
                    {`${footerSubtitle} ${footerDisclaimer} `}
                  </p>
                }
                renderElse={
                  <>
                    <p className="cgi-locked-card__footer-subtitle">
                      {footerSubtitle}
                    </p>
                    <p className="cgi-locked-card__footer-disclaimer">
                      {footerDisclaimer}
                    </p>
                  </>
                }
              />
            </div>

            <div className="cgi-locked-card__footer-button">
              <IdsMainButton
                full
                type="button"
                outline
                id={`locked-offer-${productCode}-btn`}
                data-testid={`locked-offer-cgi-btn`}
                onClick={() => trackOnContinue(productCode)}
                variant="secondary"
              >
                Desbloquear
                <IdsIcon>arrow_right_base</IdsIcon>
              </IdsMainButton>
              <p className="cgi-locked-card__footer-disclaimer">
                *Proposta sujeita à análise de crédito
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardCGI
