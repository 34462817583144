import { IdsContextualButton, IdsMainButton } from '@ids/react'
import { If, Input } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import {
  SkeletonLoaderInput,
  SkeletonLoaderInfo
} from '../../../../../../components/SkeletonLoader'
import { useEditForm } from './hooks'
import './second-refin-edit-form.scss'

const EditForm = ({ proposal, setCardButtonDisabled, offerIdx }) => {
  const { offers } = useLead()
  const currentProposal =
    offers?.others && offers?.others[offerIdx]
      ? offers?.others[offerIdx]
      : proposal
  const { maxLoanValue, minLoanValue } = currentProposal

  const {
    onSubmit,
    isFormSending,
    isEditing,
    control,
    onEditClick,
    isValid,
    isToRerender,
    requestedDefaultValue
  } = useEditForm({
    proposal,
    setCardButtonDisabled,
    offerIdx
  })

  return (
    <div className="second-refin-edit-form">
      <div className="second-refin-edit-form__field">
        <If
          condition={isFormSending}
          renderIf={<SkeletonLoaderInput />}
          renderElse={
            <>
              <Input.Currency
                id="value"
                label="Valor adicional na sua conta"
                name="value"
                type="currency"
                shouldResetOn={isToRerender}
                disabled={isFormSending || !isEditing}
                defaultValue={requestedDefaultValue}
                rules={{
                  min: minLoanValue,
                  max: maxLoanValue
                }}
                control={control}
              />

              <p className="second-refin-edit-form__disclaimer">
                Liberado até {formatters.number.toCurrency(maxLoanValue)}
              </p>
            </>
          }
        />
      </div>
      <If
        condition={!isEditing}
        renderIf={
          <IdsContextualButton
            onClick={onEditClick}
            disabled={isFormSending}
            data-testId="edit-value-btn"
          >
            Editar
          </IdsContextualButton>
        }
        renderElse={
          <IdsMainButton
            onClick={onSubmit}
            isLoading={isFormSending}
            data-testId="ok-edit-value-btn"
            disabled={isFormSending || !isValid}
          >
            Ok
          </IdsMainButton>
        }
      />
      <If
        condition={isFormSending}
        renderIf={<SkeletonLoaderInfo />}
        renderElse={
          <div className="second-refin-edit-form__info">
            <p className="second-refin-edit-form__info-title">
              Condições de pagamento
            </p>
            <strong className="second-refin-edit-form__info-value">
              Taxas de até 3,61%
            </strong>
          </div>
        }
      />
    </div>
  )
}

export default EditForm
