import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './second-credit-card-offer.scss'

const CreditCardOffer = (props) => {
  const { currentProposal } = props

  const {
    title,
    highlights,
    productCode,
    buttonText,
    buttonTrackingName,
    image
  } = currentProposal

  return (
    <div className="second-credit-card-offer">
      <div className="second-offer-card__header">
        <p className="second-offer-card__title --fixed-height">{title}</p>
      </div>

      <div className="second-offer-card__main-content">
        <figure className="second-offer-card__img-wrapper">
          <img
            src={image}
            alt={title}
            title={title}
            className="img second-offer-card__card-img"
            width="132"
            height="117"
          />
        </figure>

        <AdditionalData list={highlights} />
      </div>
      <div className="second-offer-card__final-content">
        <ButtonContinue
          {...props}
          buttonText={buttonText}
          callback={finishProposalCallback}
          id={`secondary-offer-${productCode}-btn`}
          dataTestid="secondary-offer-credit-card-offer-btn"
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default CreditCardOffer
