import { IdsIcon } from '@ids/react'

import './card-additional-data.scss'

const AdditionalData = ({ list, children }) => {
  return (
    <ul className="card-additional-data">
      {children}

      {list?.map((item, index) => {
        const { icon, text } = item

        return (
          <li
            className={`item ${
              index !== 0 && index < list.length ? '--divider' : ''
            }`}
            key={index}
            style={{ order: index }}
          >
            <IdsIcon>{icon}</IdsIcon>
            <p className="card-additional-data__text">{text}</p>
          </li>
        )
      })}
    </ul>
  )
}

export default AdditionalData
