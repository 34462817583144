import { IdsIcon } from '@ids/react'
import  Bullets from './constants'

import './bullet-points.scss'

const BulletPointsComponent = () => {
  return (
    <div className="bullet-points">
      {Bullets.map((bullets, index) => {
        return (
          <div className="bullet-points__container" key={index}>
            <div className="bullet-points__wrapper" key={index}>
              <IdsIcon>{bullets.icon}</IdsIcon>

              <span className="bullet-points__title">{bullets.title}</span>
            </div>
            <p className="bullet-points__description">{bullets.description}</p>
          </div>
        )
      })}
    </div>
  )
}

export default BulletPointsComponent
