import analytics from '@mobi/libraries/analytics'
import { goTo, segment } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from './services'

export const trackOnContinue = async (productCode) => {
  try {
    segment.buttonClick({
      currentButton: {
        innerText: 'Desbloquear',
        dataset: { id: `locked-offer-${productCode}-btn` }
      },
      payload: {
        productName: productCode,
        type: 'Desbloqueio'
      }
    })

    analytics.track('buttonClick', {
      currentPage: 'vitrine-shopping',
      detail: 'click-oferta-bloqueada',
      customLayer: {
        description: productCode
      }
    })
    const { next_step } = await services.getNextStepBlocked(productCode)

    goTo(next_step)
  } catch (error) {
    const payload = {
      error: error?.message,
      requestURL: error?.request?.responseURL
    }

    DDPageError({
      name: 'trackOnContinue',
      payload
    })
  }
}
