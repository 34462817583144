import { IdsIcon, IdsActionButton } from '@ids/react'
import analytics from '@mobi/libraries/analytics'
import classnames from 'classnames'
import { segment } from 'utils'

import './products-card.scss'

const ProductsCard = ({
  title,
  text,
  icon,
  link,
  textLink,
  className,
  trackName,
  index,
  ...props
}) => {
  const classNames = classnames('products-card', {
    [className]: className
  })

  return (
    <div className={classNames} {...props}>
      <div className="products-card__icon-wrapper">
        <IdsIcon>{icon}</IdsIcon>
      </div>

      <p className="products-card__title">{title}</p>

      <p className="products-card__description">{text}</p>

      <IdsActionButton
        className="products-card__button"
        data-testid="products-card-button"
        variant="secondary"
        onClick={(event) => {
          event.preventDefault()
          segment.buttonClick({
            currentButton: {
              innerText: textLink,
              button_name: `${text}:Cross sell`,
              destination_url: link
            },
            payload: {
              productName: trackName,
              type: ' Cross sell',
              order: index
            }
          })
          analytics.track('buttonClick', {
            currentPage: 'vitrine-shopping',
            detail: 'click-oferta-cross-sell',
            customLayer: {
              description: trackName
            }
          })
          window.open(link, '_blank')
        }}
      >
        {textLink} <IdsIcon size="S">arrow_right_base</IdsIcon>
      </IdsActionButton>
    </div>
  )
}

export default ProductsCard
