import { IdsActionButton, IdsIcon } from '@ids/react'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { webAppCallback, useLead } from '@itau-loans-www/shopping/src/hooks'

import AdditionalData from '../../../../components/AdditionalData'
import ConsorcioModal from '../../../../components/ConsorcioModal'
import Disclaimer from '../../../../components/Disclaimer'
import './second-consorcio.scss'

const Consorcio = (props) => {
  const { currentProposal } = props

  const { setOfferModalOpen } = useLead()

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    buttonTrackingName,
    secondOfferHighlights
  } = currentProposal

  return (
    <div className="second-consorcio">
      <div className="second-offer-card__main-content">
        <div className="second-offer-card__header">
          <p className="second-offer-card__title --fixed-height">{title}</p>
        </div>
        <div>
          <span className="second-consorcio__subtitle">
            Você pode comprar uma moto, uma casa, fazer uma reforma e por aí
            vai. O Consórcio Itaú é perfeito para muita gente. Simule no App!
          </span>
        </div>
        <div className="second-consorcio__text-wrapper">
          <span className="second-consorcio__text">
            No Consórcio Itaú, você encontra:
          </span>
        </div>
      </div>
      <div>
        <AdditionalData list={secondOfferHighlights} />

        <div className="second-offer-card__final-content">
          <ButtonContinue
            {...props}
            buttonText={buttonText}
            ariaLabel={buttonAriaLabel}
            callback={webAppCallback}
            id={`secondary-offer-${productCode}-btn`}
            dataTestid={'secondary-offer-consortium-btn'}
            buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
          />

          <IdsActionButton
            variant="neutral"
            id={`secondary-offer-details-${productCode}-btn`}
            data-testid={`secondary-offer-details-consortium-btn`}
            onClick={() => {
              setOfferModalOpen(true)
            }}
          >
            mais detalhes <IdsIcon size="S">arrow_right_base</IdsIcon>
          </IdsActionButton>

          <Disclaimer />
        </div>
      </div>
      <ConsorcioModal />
    </div>
  )
}

export default Consorcio
