import { IdsIcon } from '@ids/react'
import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks/useOnContinue'
import { ButtonContinue, If } from '@itau-loans-www/shopping/src/components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './hero-debts.scss'

const HeroDebts = (props) => {
  const { currentProposal } = props

  const {
    title,
    subtitle,
    buttonText,
    description,
    productCode,
    buttonAriaLabel,
    heroOfferHighlights,
    buttonTrackingName,
    installmentsNumber
  } = currentProposal

  return (
    <div>
      <div className="debts-hero">
        <div className="hero-offer__header">
          <p className="hero-offer__title">{title}</p>
        </div>

        <div className="hero-offer__content">
          <p className="debts-hero__value" data-testid="discountPercentage">
            {subtitle}
          </p>

          <AdditionalData list={heroOfferHighlights}>
            <If
              condition={installmentsNumber}
              renderIf={
                <li
                  className="item --dynamic --divider"
                  data-testid="dinamicAdditionalData"
                >
                  <IdsIcon>calendario_outline</IdsIcon>
                  <p className="card-additional-data__text">
                    parcele suas dívidas em até {installmentsNumber} vezes
                  </p>
                </li>
              }
            />
          </AdditionalData>

          <ButtonContinue
            {...props}
            buttonTracking={buttonTrackingName}
            buttonText={buttonText}
            callback={finishProposalCallback}
            buttonAriaLabel={buttonAriaLabel}
            id={`hero-offer-${productCode}-btn`}
            dataTestid={`hero-offer-${productCode}-btn`}
          />

          <p className="debts-hero__description">{description}</p>

          <Disclaimer />
        </div>
      </div>
    </div>
  )
}

export default HeroDebts
