import { useState } from 'react'

import { IdsActionButton, IdsIcon } from '@ids/react'
import { defaultCallback, useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import Disclaimer from '../../../../components/Disclaimer'
import EditForm from './components/EditForm'
import './hero-consigNcor.scss'

const ConsigNcor = (props) => {
  const { currentProposal } = props
  const { setOfferModalOpen, setOfferModalContent, offers } = useLead()
  const { setProduct } = useProposal()

  const proposal = offers?.hero ?? currentProposal

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    loanLabel,
    buttonTrackingName
  } = proposal
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  return (
    <div>
      <div className="consigNcor">
        <div className="hero-offer__header">
          <p className="hero-offer__title">{title}</p>
        </div>

        <div className="hero-offer__content">
          <div className="hero-offer__value-container">
            <EditForm
              setCardButtonDisabled={setIsButtonDisabled}
              currentProposal={proposal}
              loanLabel={loanLabel}
            />
          </div>

          <div className="consigNcor__buttons-wrapper">
            <ButtonContinue
              {...props}
              buttonTracking={buttonTrackingName}
              buttonText={buttonText}
              callback={defaultCallback}
              disabled={isButtonDisabled}
              buttonAriaLabel={buttonAriaLabel}
              dataTestid={`hero-offer-consig_ncor-btn`}
              id={`hero-offer-${productCode}-btn`}
            />
            <IdsActionButton
              disabled={isButtonDisabled}
              variant="neutral"
              id={`hero-offer-details-${productCode}-btn`}
              data-testid={`hero-offer-details-consig_ncor-btn`}
              onClick={() => {
                setOfferModalOpen(true)
                setOfferModalContent({
                  ...proposal,
                  callback: () => {
                    defaultCallback({
                      proposal: proposal,
                      setButtonLoading: () => {},
                      setProduct
                    })
                    setOfferModalOpen(false)
                  }
                })
              }}
            >
              mais detalhes <IdsIcon size="S">arrow_right_base</IdsIcon>
            </IdsActionButton>
          </div>

          <Disclaimer />
        </div>
      </div>
    </div>
  )
}

export default ConsigNcor
