import { IdsIcon } from '@ids/react'
import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue, If } from '@itau-loans-www/shopping/src/components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './second-debts.scss'

const SecondDebts = (props) => {
  const {
    currentProposal: {
      title,
      subtitle,
      buttonText,
      description,
      productCode,
      buttonAriaLabel,
      buttonTrackingName,
      secondOfferHighlights,
      installmentsNumber
    }
  } = props

  return (
    <div className="second-debts">
      <div className="second-offer-card__header">
        <p className="second-offer-card__title --fixed-height">{title}</p>
      </div>

      <div className="second-offer-card__main-content">
        <p
          className="second-debts__recovery-value"
          data-testid="discountPercentage"
        >
          {subtitle}
        </p>

        <AdditionalData list={secondOfferHighlights}>
          <If
            condition={installmentsNumber}
            renderIf={
              <li
                className="item --dynamic --divider"
                data-testid="dinamicAdditionalData"
              >
                <IdsIcon>calendario_outline</IdsIcon>
                <p className="card-additional-data__text">
                  Parcele suas dívidas em até {installmentsNumber} vezes
                </p>
              </li>
            }
          />
        </AdditionalData>
      </div>

      <div className="second-offer-card__final-content --debts">
        <ButtonContinue
          {...props}
          buttonText={buttonText}
          callback={finishProposalCallback}
          buttonAriaLabel={buttonAriaLabel}
          id={`second-offer-${productCode}-btn`}
          dataTestid={'secondary-offer-reneg_debts-btn'}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
        />

        <p className="second-debts__description">{description}</p>

        <Disclaimer />
      </div>
    </div>
  )
}

export default SecondDebts
