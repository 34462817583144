import { IdsActionButton, IdsIcon } from '@ids/react'
import { If } from '@mobi/components'

import { useTokenAgain } from './hooks'
import './token-again.scss'

const TokenAgain = ({ text = 'Receber novo código', sendSms }) => {
  const { countdown, send } = useTokenAgain({ sendSms })

  const isValidCountdown = !!countdown

  return (
    <div className="token-again">
      <div className="token-again__text">
        <p>
          Você pode receber um novo código em{' '}
          <strong>{countdown} segundos</strong>
        </p>
      </div>
      <If condition={!isValidCountdown}>
        <IdsActionButton data-testid="send-btn" variant="secondary" onClick={send}>
          <IdsIcon>recarregar</IdsIcon>
          {text}
        </IdsActionButton>
      </If>
    </div>
  )
}

export default TokenAgain
